import React, {useState, useEffect, useRef, Fragment} from "react"
import styled from 'styled-components'
import Link from "gatsby-link"
import {Helmet} from "react-helmet";
import { useSpring, animated } from 'react-spring'
import NetlifyForm from 'react-netlify-form'

import device from "../styles/device"
import colors from "../styles/colors"
import fonts from "../styles/fonts"

import Emojis from "../components/emojis"
import Layout from "../layout"

//data:
import langData from '../data/languages/ca'


const ContactPageContainer = styled.section`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1440px;

  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  margin: 100px auto 0;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const ContactPageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  margin: 0 -10px;
`

const ContactPageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 41.6666%;
  padding: 0 10px;
  box-sizing: border-box;

  @media ${device.tablet}{
    width: 100%;
  }

  .intro {
    margin: 0 0 40px;
  }

  h1 {
    font-family: ${fonts.poppins};
    color: ${colors.tertiary};
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
    margin: 0 0 35px;

    @media ${device.phone}{
      font-size: 40px;
      line-height: 45px;
    }
  }

  p {
    font-family: ${fonts.poppins};
    color: ${colors.tertiary};
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }
`

const DatosContacto = styled.div`
  p {
    white-space: pre;
    font-family: ${fonts.poppins};
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;

    strong {
      display: inline-flex;
    }

    @media(max-width: 1050px) {
      width: 100%;
    }
  }

  a {
    color: ${colors.primary};

    :hover {
        color: ${colors.primaryDark};
      }
    :active {
      color: ${colors.primaryLight};
    }

  }
`

const ContactPageForm = styled.div`
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;

  margin-left: auto;

  @media ${device.tablet}{
    width: 100%;
    margin-top: 80px;
  }

  form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  input{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(232, 232, 232);
    width: 100%;
    padding: 10px;
    margin-bottom: 20px ;
    color: ${colors.tertiary};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;

    box-sizing: border-box;
  }

  textarea{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(232, 232, 232);
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 140px;
    color: ${colors.tertiary};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;

    box-sizing: border-box;
  }

  input::placeholder, textarea::placeholder{
    color: ${colors.tertiaryGrey};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
  }

  input:hover, textarea:hover {
    border: 1px solid ${colors.primary};
  }

  input:focus, textarea:focus {
    outline: none !important;
    border: 1px solid ${colors.primaryDark};
  }

  .checkboxContainer {
    display: flex-inline;
    color: ${colors.tertiaryLight};
    font-family: ${fonts.poppins};
    font-size: 14px;
    line-height: 20px;
    margin-right: auto;
    margin-bottom: 20px;

    a {
      color: ${colors.primary};

      :hover {
        color: ${colors.primaryDark};
      }
      :active {
        color: ${colors.primaryLight};
      }
    }
  }

  input[type="checkbox"] {
    display: flex-inline;
    width: inherit;
    margin: 0;
  }

  .hidden {
    display: none;
  }
`

const ContactPageSubmit = styled.button`
  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  padding: 10px 60px;
  font-family: ${fonts.poppins};
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-top: 40px;
  margin-right: auto;
  transition: all .4s;

  &:hover {
    background-color: ${colors.primaryDark};
    cursor: pointer;
  }
`

const ContactPageStatus = styled.span`
  margin: 15px 0;
  font-family: ${fonts.poppins};
  font-size: 14px;
  font-weight: bold;
  color: ${({color}) => color};
`




const ContactPage = () => {

  const [height, setHeight] = useState();

  const FormularRef = useRef(null);

  const {sideMenu, cookies, footer, contact: {helmet, title, description, phone, email, address, address1, address2, address3, viewMap, placeholders, conditions, policyLink, button, loadingMsg, errorMsg, successMsg}} = langData;

  useEffect ( () => {
    setHeight(FormularRef.current.clientHeight)
  }, [FormularRef] )


  const DatosProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(-200px)`
    },
    to: {
      opacity: 1,
      transform: `translateX(0px)`
    }
  })

  const FormProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(200px)`
    },
    to: {
      opacity: 1,
      transform: `translateX(0px)`
    }
  })

  return (

    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>

      <Helmet>
          <title>{helmet.title}</title>
          <meta name="description" content={helmet.meta} />
      </Helmet>

      <ContactPageContainer height={height}>
        <ContactPageRow>

          <ContactPageContent>

            <animated.div style={DatosProps}>
              <h1>{title}<Emojis symbol="👋" label="waving hand sign"/></h1>
              <p className="intro">{description}</p>
              <DatosContacto>
                <p><strong>{phone}</strong><br />+34 93 268 73 64</p>
                <p><strong>{email}</strong><br /><a href="mailto:consultas@binaria.com" target="_blank" rel="noopener noreferrer">consultas@binaria.com</a></p>
                <p><strong>{address}</strong><br />{address1}<br />{address2}<br/>{address3}<br/><a href="https://www.google.com/maps/place/Aticco+Tarradellas/@41.3895288,2.1407641,17z/data=!3m1!4b1!4m5!3m4!1s0x12a499a052531d41:0x819c29d2898a44d!8m2!3d41.3895288!4d2.1429528?hl=en" target="_blank" rel="noopener noreferrer">{viewMap}</a></p>
              </DatosContacto>
            </animated.div>

          </ContactPageContent>

            <ContactPageForm ref={FormularRef}>

              <animated.div style={FormProps}>

                <NetlifyForm name='Contact from' honeypotName='bot-field'>
                  {({ loading, error, success }) => (
                    <Fragment>

                      <input name="Nombre y apellidos" aria-label="Nombre y apellidos" placeholder={placeholders.name} type="text" required/>
                      <input name="Email" aria-label="Email" placeholder={placeholders.email} type="email" required/>
                      <textarea name="Mensaje" aria-label="Mensaje" placeholder={placeholders.message} required/>
                      <div className="checkboxContainer">
                        <input name="Aceptación" type="checkbox" aria-label="Aceptación" required/>&nbsp;{conditions}&nbsp;<Link to="/politica-de-privacidad">{policyLink}</Link>
                      </div>
                      <ContactPageSubmit aria-label="Enviar" type="submit">{button}</ContactPageSubmit>

                    {loading &&
                      <ContactPageStatus color="#16a2ba">{loadingMsg}</ContactPageStatus>
                    }
                    {error &&
                      <ContactPageStatus color="#f54b5e">{errorMsg}</ContactPageStatus>
                    }
                    {success &&
                      <ContactPageStatus color="#39cb4f">{successMsg}</ContactPageStatus>
                    }

                    </Fragment>
                  )}
                </NetlifyForm>

              </animated.div>

          </ContactPageForm>

        </ContactPageRow>
      </ContactPageContainer>

    </Layout>

  )
}


export default ContactPage

